import anime from 'animejs';

export default () => {
    const triggerButton = document.querySelector('[href="#contact"]');
    const target = document.querySelector('#contact');
    const headerHeight = document.querySelector('header').clientHeight;

    if (!triggerButton) {
        return;
    }

    triggerButton.addEventListener('click', (e) => {
        e.preventDefault();

        const {top} = target.getBoundingClientRect();

        window.scrollTo({
            top: top + window.scrollY - headerHeight,
            behavior: 'smooth',
        })
    })
}
