import Velocity from "velocity-animate";
import "velocity-ui-pack";
$.fn.velocity = Velocity;

import smoothScroll from "./lib/smooth-scroll";
smoothScroll();

var $header             = $('header.header'),
    contentTopPosition  = $header.outerHeight(),
    $headerTop          = $header.find('#topbar'),
    scrollSlidePostion  = $headerTop.outerHeight(),
    headHeight          = $('#head').outerHeight(),
    $euhporiaOverlay    = $('.euphoria-menu-overlay'),
    $euphoriaMenu       = $('.euphoria-menu');


$euphoriaMenu.css('margin-top', contentTopPosition);

$(window).scroll(function(){
   if ($(this).scrollTop() > scrollSlidePostion) {
       if(!$header.hasClass('shrink')) {
           $headerTop.velocity( 'slideUp', { duration: 125 } );
           $header.addClass('shrink');
           $euphoriaMenu.css('margin-top', headHeight );
           $euhporiaOverlay.css('top', headHeight );
       }
   } else {
       if($header.hasClass('shrink')) {
           $headerTop.velocity( 'slideDown', { duration: 125 } );
           $header.removeClass('shrink');
           $euphoriaMenu.css('margin-top', contentTopPosition);
           $euhporiaOverlay.css('top', contentTopPosition);
       }
   }
});

var contentPlacement = function () {
    $('.scrollable-content').css( 'margin-top', contentTopPosition );
}
contentPlacement();

$( window ).on( 'resize', contentPlacement );

// Activeer voor Fancybox
require("fancybox")(jQuery);
require("fancybox/dist/helpers/js/jquery.fancybox-media");

// Activeer voor owl-Carousel
import "./lib/owl.carousel";
import "./lib/owl.carousel.navigation";

// Activeer Euphoria responsive menubar
import "./euphoria/responsive/menu";

//Scrollreveal
import "./lib/scrollReveal.min.js";
/* Scroll reveal */
var config = {
       mobile: true,
       vFactor: 0.20
     }
 window.sr = new scrollReveal(config);


$( '[rel^="lightbox"],a.fancybox' ).fancybox();
$( '.singleimage1' ).fancybox();
$( '.singleimage2' ).fancybox();
$(document).ready(function() {
	$(".various").fancybox({
		maxWidth	: 700,
        minWidth    : 300,
        minHeight   : 650,
		// fitToView	: true,
		width		: '70%',
		autoSize	: false,
		closeClick	: false,
		openEffect	: 'none',
        autoResize  : true,
		closeEffect	: 'none'
	});
});
$('.various').on('click touch', function () {
    var test = $(this).attr("data-link");
    $('input#field-0a551bda-dd84-11e6-a623-005056994792').val(test);
});

$('.menu a[href="/waarom-teakhout"]').mouseenter(function() {
    // $('.submenu').slideUp();
    $('.submenu').show();
});
$('.menu .submenu').mouseleave(function() {
    // $('.submenu').slideUp();
    $('.submenu').hide();
});
$(".product-sidebar .menu-item.has-children h6").click(function() {
    $(this).toggleClass('active');
    $(this).siblings('ul').slideToggle();
});

$(".product-sidebar .menu-item.has-children ul li.active").parent().show();
$(".product-sidebar .menu-item.has-children ul li.active").parent().siblings('h6').addClass('active');

var cw = $('.mod-images.element-template--voorbeeld ul li').width();
$('.mod-images.element-template--voorbeeld ul li').css({'height':cw+'px'})

// // Afbeelding hoogte bgFrame
// var bgFrameImageHeightFirst = $('.columns.two .column .mod-images ul li:first-of-type').width();
// $('.columns.two .column .mod-images ul li:first-of-type').css({'height':bgFrameImageHeightFirst+'px'})
// var bgFrameImageHeightNotFirst = $('section#bgFrame .content .columns.two .column .mod-images ul li:not(:first-of-type)').width();
// $('.columns.two .column .mod-images ul li:not(:first-of-type)').css({'height':bgFrameImageHeightNotFirst+'px'})

$.euphoriaMenu()
$( ".sub-menu ul li" ).clone().appendTo( ".euphoria-menu>ul" );
$(".euphoria-menu>ul li.page-11").addClass('has-children animated');
$(".submenu ul").clone().appendTo(".euphoria-menu>ul li.page-11");
$('.euphoria-menu ul li.page-5').clone().prependTo('.euphoria-menu>ul');
$(".euphoria-menu>ul li.page-11").append('<a href="javascript:;" class="expand"></a>');
$('.euphoria-menu>ul li.page-11 .expand').click(function() {
    $('.euphoria-menu>ul li.page-11 ul').slideToggle();
    $('.euphoria-menu>ul li.page-11').toggleClass('open');
});
$('.layout-contact .form-field-select select').prepend('<option value="" disabled selected>Ik heb een vraag over</option>');

$('.mod-webshop-overview-v2 .mod_webshopcartv2_default .phone-sidebar-button a').click(function(event) {
    event.preventDefault();
    $(this).toggleClass('active');
    $('.mod-webshop-overview-v2 .mod_webshopcartv2_default .product-sidebar').slideToggle();
});
// FORM PLACEHOLDER
        function placeholderIsSupported() {
            var test = document.createElement('input');
            return ('placeholder' in test);
        }

        if( placeholderIsSupported() ) {
            $('.inputtext, .textarea').each( function ()
            {
                var $label = $(this).find('label').detach(),
                $input = $(this).find('input, textarea').first()

                $input.attr( 'placeholder', $label.text().replace( /\:( \*)?$/, '$1' ) )
            } )
        }


/****************************************
Input
*****************************************/
        $('input:radio[value="Klant-organisatie"]').change(
            function(){
                if ($(this).is(':checked')) {
                    $('#radioelements_type_Klant-organisatie').slideDown();
                }
            });
            $('input:radio[value="Klant-particulier"]').change(
                function(){
                    if ($(this).is(':checked')) {
                        $('#radioelements_type_Klant-organisatie').slideUp();
                    }
                });
                $('input:radio[name="other_delivery_address"]').change(
                    function(){
                        if ($(this).is(':checked')) {
                            $('#radioelements_other_delivery_address_yes').slideToggle();
                        }
                    });
                    /****************************************
                    Bricks
                    *****************************************/
                    import Bricks from 'bricks.js'

                    // define your grid at different breakpoints, mobile first (smallest to largest)
                    const sizes = [
                        { columns: 2, gutter: 5 },
                        { mq: '578px', columns: 2, gutter: 10 },             // assumed to be mobile, because of the missing mq property
                        { mq: '736px', columns: 3, gutter: 10 },
                        { mq: '1024px', columns: 4, gutter: 10 }
                    ]

                    // create an instance
                    $('.element-template--bricks').each(function(i) {
                        let instance = Bricks({
                            container: '.grid',
                            packed:    'data-packed',        // if not prefixed with 'data-', it will be added
                            sizes:     sizes
                        })

                        instance.resize(true)     // bind resize handler
                        instance.pack()           // pack initial items
                        instance.update()
                    });


                    // start it up, when the DOM is ready
                    // note that if images are in the grid, you may need to wait for document.readyState === 'complete'
                    let $masonry = $('.element-template--bricks')

                    $masonry.parent().parent().attr("id","fotoboek")

                    $masonry.addClass('loading');

                    if( $( window ).width() > 375 ) {
                        $(window).on("load", function() {
                            if(!$masonry.length) {
                            	return;
                            }
                            $masonry.removeClass('loading');
                        });
                    };

function formatPrice(price) {
	return price.toLocaleString('nl-NL', {
	    minimumFractionDigits: 2
	}).replace(',00', ',-')
}

var selectPrice = function () {
    var $this = $(this);
    var $wrapper = $this.parents('.selectboxes-wrapper');
    var $variantSelect = $this.find('[name="variant"]');
    var $measureSelect = $this.find('[name="subproduct"]');
    var $selects = $variantSelect.add($measureSelect);
    var $addButton = $wrapper.find('.add-to-cart');
    var $pricing = $wrapper.find('.pricing');

    $selects.change(function(e) {
        var key = $measureSelect.val() + "" + $variantSelect.val();
        console.log('key', key);
        console.log('data', data);
        if(!(key in data)) {
            return;
        }
        console.log('result', data[key]);

        $pricing.text(formatPrice(data[key]['price']));
    }).change();

    $addButton.click(function(e) {
        e.preventDefault();

        var key = $measureSelect.val() + "" + $variantSelect.val();
        if(!(key in data)) {
            return;
        }

        fg.Store.Cart.addProduct(data[key]['id'], 1);
    });
}

$('.selectboxes').each(selectPrice)

fg.events.on('store:cart:updated', data => {
    $('#total_price').text(formatPrice(data.totals.price));
    $('.amount').text(data.totals.quantity);

    $('.cart_add').toggleClass('active');
    $('.button.order.add-to-cart').toggleClass('clicked');
    setTimeout(function(){
          // toggle back after 1 second
          $('.cart_add').toggleClass('active');
          $('.button.order.add-to-cart').toggleClass('clicked')
    },3000);
});


// Payment method

var $form = $('#WebShopCartStep form');
var $field = $form.find('#payment_method');

if($field.length > 0) {
    var $methods = $form.find('#payment_method label');
    var currentGroup = null;
    var methodSelect = '<div class="form-group form-group-radio MOD_WEBSHOPCARTV2_STEP_TWO"><div class="form-field form-field-radio radiobutton"><label>Selecteer hier uw verzendmethode. Onze meubels bezorgen wij zelf, geef hiervoor uw postcode door. Uw verzorgingsartikelen en accessoires laten wij per post bezorgen, daarvoor selecteer u bezorging via TNT. Heeft u een combinatie van producten? Selecteer dan uw postcode, dan brengen wij alles aan huis. Uiteraard kunt u uw bestelling ook afhalen in één van onze filialen.<select name="payment_method">';

    jQuery.each($methods, function(i, el) {
        var $el = jQuery(el);
        var $radio = $el.find('input:radio');

        if($radio.length <= 0) {
            return;
        }

        var text = $el.text();
        var groupName = text.split(" ")[0];

        if(groupName !== currentGroup) {
            methodSelect += '<optgroup label="' + groupName + '">';
        }
        methodSelect += '<option value="' + $radio.val() + '"' + ($radio.is(':checked') ? 'selected' : '') + '>' + text + '</option>';
        currentGroup = groupName;

        if(groupName !== currentGroup) {
            methodSelect += '</optgroup>';
        }
    });

    $field.hide();
    $form.prepend(methodSelect + '</label></select></div>');
}

var $productInfoForm = $('#element-b57c1028-6063-11e6-9859-005056994792');
var $productName = $('#form-subject');
if($productInfoForm.length && $productName.length) {
    var text = $productName.text().trim();
    if (text != 'ALL_PRODUCTS') {
        $productInfoForm.find('#formElement24').val(text);
    }
}

// Lightbox tiles en row
$('.mod-webshop-overview-v2 .mod_webshopcartv2_default .mod_webshopoverview_product .detail_howto .colors:not(.alt) a, .mod-webshop-overview-v2 .mod_webshopcartv2_default .mod_webshopoverview_product .mod_webshopoverview_detail .detail_box .product_overview .colors.alt a').attr('rel', 'stahp');

$('.element-template--lightbox-tiles .item, .element-template--lightbox-rows .item').each( function () {
	var $el = $(this),
		$images = $el.find('.images img'),
		images = [],
		$button = $el.find('.controls .button')

	$images.each(function () {
		images.push( $(this).attr('src') )
	})

	$(this).find('.controls .button, .image').on('click', function ( e ) {
		e.preventDefault()

		$.fancybox.open(images)
	})
} )

$('.koopzondagen .knop').click(function() {
    $(this).parents('.koopzondagen').toggleClass('active');
    $(this).parents('.koopzondagen').find('.text').slideToggle();
});
